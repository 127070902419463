import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById('root');

if (rootElement !== null) {
  (async () => {
    const { default: App } = await import('./App');
    const { RootModalContainer } = await import(
      './shared/components/modals/prev/RootModalContainer'
    );

    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <GoogleOAuthProvider clientId="283281005404-8ghtjh51i0h7u9sii04tm6c9anbo1qkf.apps.googleusercontent.com">
          <Router>
            <App />
            <RootModalContainer />
            <ToastContainer
              position="top-right"
              autoClose={3500}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              theme="light"
            />
          </Router>
        </GoogleOAuthProvider>
      </React.StrictMode>
    );
  })().catch(error => {
    console.error('Error during app initialization:', error);
  });
} else {
  console.error("Element with ID 'root' not found.");
}

reportWebVitals().catch(error => {
  console.error('Error while running reportWebVitals:', error);
});
